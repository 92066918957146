import React, { useState, useRef } from "react"
import Layout from "../../components/layout"
import { Box, Typography, Container } from "@mui/material"
import videoSrc from "../../assets/videos/natm.mp4"

const museumAR = () => {
  return (
    <Layout>
      <Container
        maxWidth="fit-content"
        disableGutters
        sx={{
          backgroundColor: "black",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontFamily: "clash display, sans-serif",
            fontSize: { xs: "1.5rem", md: "3rem" },
            color: "white",
            textAlign: "center",
            lineHeight: "150%",
            paddingTop: "130px",
            paddingBottom: "30px",
            fontWeight: "600",
            justifyContent: "center",
          }}
        >
          National Atomic Testing Museum
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Enable more interactive content for Museums
        </Typography>
      </Container>

      <Container
        sx={{
          width: "100vw",
          padding: "0",
          paddingLeft: "0",
          paddingRight: "0",
          maxWidth: "100vw",
          height:"70vh",
          backgroundColor: "black",
          display: "flex",
          justifyContent: "center",
        }}
        maxWidth="100vw"
        disableGutters
      >
        <video style={{ height: "50vh",margin:"70px 0" }} src={videoSrc} controls></video>
        
{/* 
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/axcmbMqx1-8?rel=0"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> */}
      </Container>
    </Layout>
  )
}

export default museumAR
